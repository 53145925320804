import {
  Box,
  Divider,
} from "@mui/material";
import EquivalenceX from "./CarbsCalculator/EquivalentX";
import CruCuit from "./CarbsCalculator/CruCuit";

export default function CarbsCalculator() {
  return (
    <Box>
      <EquivalenceX />
      <Divider sx={{ marginY: 3 }} />
      <CruCuit />
    </Box>
  );
}
