import { useContext, useEffect, useState } from "react";
import UserContext from "../../../Data/UserContext";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import AssetContext from "../../../Data/AssetsContext";

type GroceryItem = {
  [key: string]: number;
};

type GroceryList = {
  [key: string]: GroceryItem;
};

export default function Courses() {
  const { member } = useContext(UserContext);
  const { ingredients } = useContext(AssetContext);
  const [groceriesList, setGroceriesList] = useState<GroceryList>({});

  useEffect(() => {
    const list: GroceryList = {};

    member.Program.Days.forEach((day) => {
      day.Meals.forEach((meal) => {
        meal.MealItems.forEach((mealItem) => {
          const foodSoid = mealItem.FoodSoid;
          const quantity = mealItem.Quantity;

          const foodType = ingredients.find((i) => i.Soid === foodSoid)?.Type;

          if (!foodType) return; // Skip if Type is not found

          if (!list[foodType]) {
            list[foodType] = {};
          }

          if (list[foodType][foodSoid]) {
            list[foodType][foodSoid] += quantity;
          } else {
            list[foodType][foodSoid] = quantity;
          }
        });
      });
    });

    setGroceriesList(list);
  }, [member.Program.Days, ingredients]);

  return (
    <Container>
      <Typography variant="h4" align="center">
        Courses de la semaine
      </Typography>
      {Object.entries(groceriesList).map(([type, items]) => (
        <Box key={type} my={2}>
          <Typography variant="h5" gutterBottom textAlign="center" sx={{textDecoration: "underline"}} >
            {type}
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(items).map(([foodSoid, quantity]) => (
              <Grid item key={foodSoid} xs={12} md={4}>
                <Card sx={{ maxWidth: 345}}>
                  <CardActionArea>
                    {/* <CardMedia
                      component="img"
                      height="140"
                      image="https://foodomarket.s3.eu-west-3.amazonaws.com/media/cache/front_big/f6/bb/6c37929eeb7fb6c603d63d4cfd52.png"
                      alt={ingredients.find((i) => i.Soid === foodSoid)?.Name}
                    /> */}
                    <CardContent sx={{padding: "8px"}}>
                      <Typography gutterBottom variant="h6" component="div" >
                        {ingredients.find((i) => i.Soid === foodSoid)?.Name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        {quantity.toString() + " Grammes"}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Container>
  );
}
