import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Axios from "axios";
import "./App.css";
import Home from "./Components/User/Home";
import Administration from "./Components/Admin/Administration";
import Member from "./Components/Admin/Member/Member";
import AxiosContext from "./Data/AxiosContext";
import { Food, Exercice } from "./Data/Assets";
import AssetContext from "./Data/AssetsContext";
import Survey from "./Components/Survey/Survey";
import { MainApplication } from "./Components/User/MainApplication";
import History from "./Components/User/Menus/History";
import Courbe from "./Components/User/Menus/Courbe";
import InProgress from "./Components/User/Menus/InProgress";
import Courses from "./Components/User/Menus/Courses";
import NotFound from "./404";

const loadEndPoint = () => {
  switch (window.location.hostname) {
    case "localhost":
      //return "https://app-api.valeoperformance.com";
      return "https://localhost:24001/";
    case "valeo.kim-berly.fr":
      return "https://valeo-api.kim-berly.fr/";
    case "app.valeoperformance.com":
      return "https://app-api.valeoperformance.com";
  }
};

const endPoint = loadEndPoint();
const axiosHelper = Axios.create({ baseURL: endPoint });

export default function App() {
  const [ingredients, setIngredients] = useState<Food[]>([] as Food[]);
  const [exercices, setExercices] = useState<Exercice[]>([] as Exercice[]);

  useEffect(() => {
    axiosHelper("api/Assets/Ingredients")
      .then((response) => {
        setIngredients(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    axiosHelper("api/Assets/Exercices")
      .then((response) => {
        setExercices(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <AssetContext.Provider
      value={{
        exercices: exercices || ([] as Exercice[]),
        ingredients: ingredients || ([] as Food[]),
        setExercices: setExercices,
        setIngredients: setIngredients,
      }}
    >
      <AxiosContext.Provider
        value={{
          axiosHelper: axiosHelper,
          loadEndPoint: loadEndPoint,
        }}
      >
        <Router>
          <Routes>
            <Route
              caseSensitive={false}
              path="/administration/*"
              element={<Administration />}
            />
            <Route path="/" element={<Home />}>
              <Route index element={<MainApplication />} />
              <Route
                path="History"
                caseSensitive={false}
                element={<History />}
              />
              <Route path="Courbe" caseSensitive={false} element={<Courbe />} />
              <Route
                path="Courses"
                caseSensitive={false}
                element={<Courses />}
              />
              {/* <Route path="Settings" caseSensitive={false} element={<Settings />} /> */}
              <Route
                path="Settings"
                caseSensitive={false}
                element={<InProgress />}
              />
            </Route>
            <Route
              caseSensitive={false}
              path="/member/:id"
              element={<Member />}
            />
            <Route
              caseSensitive={false}
              path="/questionnaire"
              element={<Survey />}
            />
            {/* 404 Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </AxiosContext.Provider>
    </AssetContext.Provider>
  );
}
