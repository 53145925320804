import { Typography, Grid, TextField, Select, MenuItem } from "@mui/material";
import { useState, useMemo, useEffect } from "react";

export default function EquivalenceX() {
  const [poids, setPoids] = useState<number>(1);
  const [element1, setElement1] = useState<number>(0);
  const [element2, setElement2] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const carbsElements = useMemo(
    () => [
      { name: "Céréales", multiplicator: 1, id: 0 },
      { name: "Pommes de terre", multiplicator: 4.5, id: 1 },
      { name: "Pain", multiplicator: 1.28, id: 2 },
    ],
    []
  );

  useEffect(() => {
    const compute = () => {
      const multiplicator1 = carbsElements[element1]?.multiplicator || 0;
      const multiplicator2 = carbsElements[element2]?.multiplicator || 0;
      const result = poids * (multiplicator2 / multiplicator1);
      setTotal(result);
    };

    compute();
  }, [poids, element1, element2, carbsElements]);

  return (
    <>
      <Typography>Équivalence en féculents (Cru)</Typography>
      <Grid container spacing={2} p={1}>
        <Grid item xs={12} sm={3}>
          <TextField
            type="number"
            value={poids}
            onChange={(e) => {
              setPoids(Number(e.target.value) || 0);
            }}
            label="Grammes"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            value={element1}
            onChange={(e) => {
              setElement1(Number(e.target.value));
            }}
          >
            {carbsElements.map((carb) => (
              <MenuItem value={carb.id} key={carb.id}>
                {carb.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            value={element2}
            onChange={(e) => {
              setElement2(Number(e.target.value));
            }}
          >
            {carbsElements.map((carb) => (
              <MenuItem value={carb.id} key={carb.id}>
                {carb.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={3} alignSelf="center">
          <Typography>Total: {total}</Typography>
        </Grid>
      </Grid>
    </>
  );
}
